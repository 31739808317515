import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {   useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "src/redux/store";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import ATMTableHeader from "src/components/UI/atoms/ATMTableHeader/ATMTableHeader";
import {
  setRowsPerPage,
  setPage,
  setSearchValue,
  setOrderByValue
} from "src/redux/slices/EmployerCertificateUpdateSlice";
import AuthHOC from "src/userAccess/AuthHOC";
import { AccessAction } from "src/utils/Enums/AccessAction";
import { EmployerCertificateUpdateListResponse } from "src/models/EmployerCertificateUpdate.model";
import FilterEmployerCertificateCardWrapper from "./filter/FilterEmployerCertificateCardWrapper";

type Props = {
  columns: columnTypes[];
  rows: any[];
};

const  EmployerCertificate = ({
  columns,
  rows,
}: Props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const EmployerCertificateDoneApplicationState: any = useSelector((state: RootState) => state.EmployerCertificateDoneApplication);
  
  const { page, rowsPerPage, isTableLoading, searchValue, totalItems, orderByValue } =
  EmployerCertificateDoneApplicationState;

  return (

      <div className="border flex flex-col overflow-auto rounded bg-white ">
        {/*Table Header */}
        <ATMTableHeader
          page={page}
          rowCount={totalItems}
          rowsPerPage={rowsPerPage}
          rows={rows}
          onRowsPerPageChange={(newValue) => dispatch(setRowsPerPage(newValue))}
          searchValue={searchValue}
          onSearchChange={(newValue) => dispatch(setSearchValue(newValue))}
          orderValue={orderByValue}
          onOrderByChange={(newValue)=>dispatch(setOrderByValue(newValue))} 
          isShowOrder={true}
          renderFilter={(close) => <FilterEmployerCertificateCardWrapper close={close} />}
          isFilter
        />

        {/* Table */}
        <div className="border flex flex-col grow overflow-auto rounded bg-white">
          <ATMTable
            columns={columns}
            isLoading={isTableLoading}
            disableRowClick={
              !AuthHOC({
                type: "ACTION",
                moduleName: "EMPLOYER_CERTIFICATE",
                action: AccessAction.VIEW,
                resultType: "BOOLEAN",
              })
            }  rows={rows}
            rowExtraClasses={(row) => "min-h-[40px]"}
          //   onRowClick={(row: EmployerCertificateUpdateListResponse) => navigate(`/pan/${row._id}`)
          // }
          />
        </div>

        {/* Pagination */}
        <div className="flex items-center justify-end border-t border-slate-300">
          <ATMPagination
            page={page}
            rowCount={totalItems}
            rows={rows}
            rowsPerPage={rowsPerPage}
            onPageChange={(newPage) => dispatch(setPage(newPage))}
          />
        </div>
      </div>
  );
};

export default  EmployerCertificate;
