import React, { useEffect, useState } from "react";
import { ErrorMessage, FormikProps } from "formik";
import { FormInitialValues } from "./DownloadDialogWrapper";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { MdEmail } from "react-icons/md";
import ATMHTMLEditor from "src/components/UI/atoms/formFields/ATMHTMLEditor/ATMHTMLEditor";
import { EmployerCertificateUpdateListResponse } from "src/models/EmployerCertificateUpdate.model";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useAddEmployerCertificateMutation } from "src/services/EmployerCertificateService";
import { showToast } from "src/utils/toaster/showToast";

type Props = {
  businessEnquiryData: EmployerCertificateUpdateListResponse | null;
  onClose: () => void;
};

const DownloadDialog = ({ businessEnquiryData, onClose }: Props) => {
  const [randomNumber, setRandomNumber] = useState<number | null>(null);
  const [todayDate, setTodayDate] = useState<string>("");

  const generateRandomNumber = () => {
    const random = Math.floor(Math.random() * 5) + 1;
    setRandomNumber(random);
  };

  const formatDate = () => {
    const today = new Date();
    const day = today.getDate();
    const year = today.getFullYear();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const month = monthNames[today.getMonth()];
    setTodayDate(`${day} ${month} ${year}`);
  };

  useEffect(() => {
    generateRandomNumber();
    formatDate();
  }, []);

  const [addEmployerCertificate] = useAddEmployerCertificateMutation();

  const generatePDF = () => {
    const input = document.getElementById("pdfContent") as HTMLElement;
    console.log("input", input, input?.outerHTML)
    addEmployerCertificate({
      html: input?.outerHTML,
      panId: businessEnquiryData?._id,
    }).then((res: any) => {
      if (res.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        console.log("res?.data", res?.data);
        if (
          res?.data &&
          res?.data?.data &&
          res?.data?.data?.employerertificateFile
        ) {
          downloadPdfFile(res?.data?.data?.employerertificateFile);

          console.log(res);
          showToast("success", res?.data?.message);
          onClose();
        }
      }
    });
  };

  const downloadPdfFile = async (pdfUrl: string) => {
    try {
      const response = await fetch(pdfUrl, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch the PDF file.");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const segments = pdfUrl.split(/[\/?#]/);
      const fileName = segments[segments.length - 1] || "downloaded-file.pdf";

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <>
      <div>
      <div
      id="pdfContent"
      style={{
        width: "210mm",
        margin: "auto",
        padding: 40,
        marginBottom: "10px",
        fontFamily: "Arial, sans-serif",
        fontSize: "14px",
        lineHeight: "1.5",
      }}
    >
      {/* Header Section */}
      <div style={{ textAlign: "center", marginBottom: 10 }}>
        <img
          src={businessEnquiryData?.logos?.image || ""}
          alt="Company Logo"
          style={{ width: "100%", height: "120px" }}
        />
        <p style={{ margin: 'auto auto 1rem', marginTop: 10, fontWeight: 700, textTransform: "capitalize" }}>
        {
          businessEnquiryData?.appliedByType === "RETAILER"
            ? `C/O ${businessEnquiryData?.retailerFirmName}, ${businessEnquiryData?.appliedByUserCurrentAddress || businessEnquiryData?.retailerFirmAddress || "N/A"}`
            : businessEnquiryData?.appliedByType === "DISTRIBUTOR"
              ? businessEnquiryData?.distributorData?.firmName && businessEnquiryData?.appliedByUserCurrentAddress
                ? `C/O ${businessEnquiryData?.distributorData?.firmName}, ${businessEnquiryData?.appliedByUserCurrentAddress}`
                : "N/A"
              : "N/A"
        }

        </p>
      </div>
      <hr />
      {/* Title Section */}
      <div style={{ textAlign: "center", fontSize: 24, fontWeight: "bold", marginBottom: 10, textDecoration: "underline" }}>
        Annexure - B
      </div>
      <p style={{ textAlign: "center", marginBottom: 10 }}>
        (Certificate to be used by the Employer on the letterhead of the organization/institution under sub-rule (4) of rule
        114 of the Income-tax Rules, 1962)
      </p>
      {/* Right-side Box */}
      <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
      <div style={{ width: "60%" }}></div>
            <div style={{ width: "40%" }}>
              <div
                style={{
                  width: "60%",
                  borderStyle: "solid",
                  borderWidth: 2,
                  borderColor: "black",
                  margin: "15px 0px",
                }}
              >
                <p style={{ fontSize: 14, margin: 0, padding: 8 }}>
                  (Affix same photograph as affixed on PAN application form)
                </p>
              </div>
              <p style={{ fontSize: 14, marginBottom: 15 }}>
                (To be attested by issuing authority with his/her signature and
                rubber stamp appearing half on the photograph and half of the
                certificate)
              </p>
            </div>
        </div>
      {/* Certificate Content */}
      <p style={{margin:'auto auto 1rem'}}>
        <strong>It is hereby certified that Sh./Smt./Kum:</strong>{" "}
        <span style={{ textDecoration: "dotted" }}>{businessEnquiryData?.acknowledgement_name || "..........................."}</span>, Son/Daughter of:{" "}
        <span style={{ textDecoration: "dotted" }}>{businessEnquiryData?.acknowledgement_father_name || "..........................."}</span> is employed with us since <span style={{ textDecoration: "underline" }}>{businessEnquiryData?.randomNumber || 2}</span> Years. 
        He/She is presently working at the following address of the organization:
      </p>
      <p style={{margin:'auto auto 1rem'}}>
        <strong>Office Address:</strong>{" "}
        <span style={{ textDecoration: "underline" }}>    
       
          {/* {businessEnquiryData?.appliedByUserCurrentAddress
  ? `C/O ${businessEnquiryData?.retailerFirmName}, ${businessEnquiryData?.appliedByUserCurrentAddress}`
  : businessEnquiryData?.retailerFirmAddress ||
    `C/O ${businessEnquiryData?.retailerFirmName}, ${businessEnquiryData?.retailerFirmAddress}` || "N/A"
} */}

{
          businessEnquiryData?.appliedByType === "RETAILER"
            ? `C/O ${businessEnquiryData?.retailerFirmName}, ${businessEnquiryData?.appliedByUserCurrentAddress || businessEnquiryData?.retailerFirmAddress || "N/A"}`
            : businessEnquiryData?.appliedByType === "DISTRIBUTOR"
              ? businessEnquiryData?.distributorData?.firmName && businessEnquiryData?.appliedByUserCurrentAddress
                ? `C/O ${businessEnquiryData?.distributorData?.firmName}, ${businessEnquiryData?.appliedByUserCurrentAddress}`
                : "N/A"
              : "N/A"
        }

</span>
      </p>
      <p style={{margin:'auto auto 1rem'}}>
        <strong>The residential address of the applicant as verified by us is given below:</strong>
      </p>
      <p style={{margin:'auto auto 1rem'}}>
        Residential Address: <span style={{ textDecoration: "dotted" }}>{businessEnquiryData?.acknowledgement_address || "........................................................................."}</span>
      </p>
      <p style={{margin:'auto auto 1rem'}}>Registration Number of the Company/Institution etc.: N/A</p>
      <p style={{margin:'auto auto 1rem'}}>PAN of the Company/Institution: N/A</p>
      <p style={{margin:'auto auto 1rem'}}><strong>Details of the Individual issuing the certificate:</strong></p>
      <p style={{margin:'auto auto 1rem'}}>
        Full Name: <span style={{ textDecoration: "underline", textTransform: "capitalize", }}>
          
        {
          businessEnquiryData?.appliedByType === "RETAILER"
            ? `${businessEnquiryData?.retailerData?.name || businessEnquiryData?.retailerName || "N/A"}`
            : businessEnquiryData?.appliedByType === "DISTRIBUTOR"
            ?  `${businessEnquiryData?.distributorData?.name ||  "N/A"}`
            : "N/A"
        }

</span> 
          {/* {businessEnquiryData?.retailerName || "N/A"}</span> */}
      </p>
      <p style={{margin:'auto auto 1rem'}}>Designation: Proprietor</p>
      <p style={{margin:'auto auto 1rem'}}>
        PAN of the Individual: <span style={{ textDecoration: "underline", textTransform: "capitalize", }}>
          {/* {businessEnquiryData?.retailerPanNumber || "N/A"} */}
          {
          businessEnquiryData?.appliedByType === "RETAILER"
            ? `${businessEnquiryData?.retailerData?.panNumber || businessEnquiryData?.retailerPanNumber || "N/A"}`
            : businessEnquiryData?.appliedByType === "DISTRIBUTOR"
            ?  `${businessEnquiryData?.distributorData?.panNumber ||  "N/A"}`
            : "N/A"
        }
        </span>
      </p>
      <p style={{margin:'auto auto 1rem'}}>
        Office Address with Location: <span style={{ textDecoration: "underline", textTransform: "capitalize", }}>
        { businessEnquiryData?.retailerData?.district
    || businessEnquiryData?.distributorData?.district 
    || "N/A" }


</span>
      </p>
      
      <div style={{ display: "flex", justifyContent: "space-between", marginRight:70, marginTop: 10 }}>
        <div>
          <p style={{margin:'auto auto 1rem'}}>Telephone: N/A</p>
          <p style={{margin:'auto auto 1rem'}}>
            Mobile: <span style={{ textDecoration: "underline" }}>
              
              { businessEnquiryData?.retailerMobileNumber 
    || businessEnquiryData?.distributorData?.mobileNumber 
    || "N/A" }
</span>
          </p>
          <p style={{margin:'auto auto 1rem'}}>Date: {todayDate}</p>
          <p style={{margin:'auto auto 1rem'}}>Place: <span  style={{ textDecoration: "underline", textTransform: "uppercase", }}>   { businessEnquiryData?.retailerData?.district
    || businessEnquiryData?.distributorData?.district 
    || "N/A" }


</span></p>
        </div>
        <div style={{ textAlign: "right" }}>
          <p>(Signature)</p>
          <p>Office Seal</p>
        </div>
      </div>
    </div>
    {/* <br /><br /><br />
        <div
          id="pdfContent"
          style={{
            width: "210mm",
            margin: "auto",
            padding: 40,
            marginBottom: "20px",
          }}
        >
          <div style={{ textAlign: "center", marginBottom: 20 }}>
            <img
              src={
                businessEnquiryData?.logos?.image
                  ? businessEnquiryData?.logos?.image
                  : ""
              }
              alt="Company Logo"
              style={{ width: "100%", height: "120px" }}
            />
            <p
              style={{
                margin: 0,
                marginTop: 10,
                fontWeight: 700,
                textTransform: "capitalize",
              }}
            >
              { businessEnquiryData?.retailerData?.address?'C/O '+businessEnquiryData?.retailerData?.firmName+', '+  businessEnquiryData?.retailerData?.address + ' ' + businessEnquiryData?.retailerData?.area+ ' ' + businessEnquiryData?.retailerData?.cityVillageName+ ' ' + businessEnquiryData?.retailerData?.district
              + ' ' + businessEnquiryData?.retailerData?.state+ ' ' + businessEnquiryData?.retailerData?.pincode: businessEnquiryData?.retailerFirmAddress
                ? businessEnquiryData?.retailerFirmAddress
                : "N/A"}
            </p>
          </div>
          <hr />
          <div
            style={{
              textAlign: "center",
              fontSize: 24,
              fontWeight: "bold",
              marginBottom: 2,
              marginTop: 20,
              textDecoration: "underline",
            }}
          >
            Annexure - B
          </div>
          <p style={{ marginBottom: 5, textAlign: "center", marginTop: 0 }}>
            (Certificate to be used by the Employer on the letterhead of the
            organization/institution under sub-rule (4) of rule 114 of the
            Income-tax Rules, 1962)
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "60%" }}></div>
            <div style={{ width: "40%" }}>
              <div
                style={{
                  width: "60%",
                  borderStyle: "solid",
                  borderWidth: 2,
                  borderColor: "black",
                  margin: "30px 0px",
                }}
              >
                <p style={{ fontSize: 14, margin: 0, padding: 8 }}>
                  (Affix same photograph as affixed on PAN application form)
                </p>
              </div>
              <p style={{ fontSize: 14, marginBottom: 35 }}>
                (To be attested by issuing authority with his/her signature and
                rubber stamp appearing half on the photograph and half of the
                certificate)
              </p>
            </div>
          </div>
          <div style={{ fontSize: 14, lineHeight: "1.5" }}>
            <div style={{ marginBottom: 15 }}>
              <label style={{ fontWeight: "bold" }}>
                It is hereby certified that Sh./Smt./Kum:{" "}
              </label>
              <p style={{ textDecoration: "underline", display: "inline", minWidth:'150px' }}>
                {businessEnquiryData?.acknowledgement_name?businessEnquiryData?.acknowledgement_name:'N/A'}{" "}
              </p>
              <label style={{ marginLeft: 10 }}>Son/Daughter of:{" "}</label>

              <p style={{ textDecoration: "underline", display: "inline", minWidth:'150px' }}>
                {businessEnquiryData?.acknowledgement_father_name?businessEnquiryData?.acknowledgement_father_name:'N/A'}{" "}
              </p>
              <label>is employed with us since </label>

              <p style={{ textDecoration: "underline", display: "inline" }}>
                {businessEnquiryData?.randomNumber
                  ? businessEnquiryData?.randomNumber
                  : 2}
              </p>
              <label style={{ marginLeft: 10 }}>
                Years. He/She is presently working at the following address of
                the organization:
              </label>
            </div>
            <div style={{ marginBottom: 15 }}>
              <label style={{ fontWeight: "bold" }}>Office Address:</label>
              <input
                type="text"
                // placeholder="Enter Office Address"
                defaultValue= { businessEnquiryData?.retailerData?.address?'C/O '+businessEnquiryData?.retailerData?.firmName+', '+  businessEnquiryData?.retailerData?.address + ' ' + businessEnquiryData?.retailerData?.area+ ' ' + businessEnquiryData?.retailerData?.cityVillageName+ ' ' + businessEnquiryData?.retailerData?.district
                  + ' ' + businessEnquiryData?.retailerData?.state+ ' ' + businessEnquiryData?.retailerData?.pincode: businessEnquiryData?.retailerFirmAddress
                    ? businessEnquiryData?.retailerFirmAddress
                    : "N/A"}
                readOnly
                style={{
                  border: "none",
                  borderBottom: "1px solid black certificate-input",
                  width: "80%",
                  padding: 3,
                  textTransform: "capitalize",
                  textDecoration: "underline",
                }}
              />
            </div>
            <div style={{ marginBottom: 15 }}>
              <label style={{ fontWeight: "bold" }}>
                The residential address of the applicant as verified by us is
                given below:
              </label>
            </div>
            <div style={{ marginBottom: 15 }}>
              <label>Residential Address: </label>
              <input
                type="text"
                // placeholder="Enter Residential Address"
                defaultValue={businessEnquiryData?.acknowledgement_address?businessEnquiryData?.acknowledgement_address:''}
                readOnly
                style={{
                  border: "none",
                  borderBottom: "1px solid black certificate-input",
                  width: "80%",
                  padding: 3,
                  textTransform: "capitalize",
                  textDecoration: "underline",
                }}
              />
            </div>
            <div style={{ marginBottom: 15 }}>
              <label>
                Registration Number of the Company/Institution etc.:
              </label>
              <input
                type="text"
                // placeholder="Enter Registration Number"
                defaultValue={"N/A"}
                readOnly
                style={{
                  border: "none",
                  borderBottom: "1px solid black certificate-input",
                  width: 300,
                  padding: 3,
                }}
              />
            </div>
            <div style={{ marginBottom: 15 }}>
              <label>PAN of the Company/Institution:</label>
              <input
                type="text"
                // placeholder="Enter PAN"
                defaultValue={"N/A"}
                readOnly
                style={{
                  border: "none",
                  borderBottom: "1px solid black certificate-input",
                  width: 300,
                  padding: 3,
                }}
              />
            </div>
            <div style={{ fontWeight: "bold", marginBottom: 15 }}>
              <label>Details of the Individual issuing the certificate:</label>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ width: "60%" }}>
                <div style={{ marginBottom: 10 }}>
                  <label>Full Name: </label>
                  <input
                    type="text"
                    // placeholder="Enter Full Name"
                    defaultValue={ businessEnquiryData?.retailerData?.name?businessEnquiryData?.retailerData?.name: businessEnquiryData?.retailerName 
                        ? businessEnquiryData?.retailerName
                        : ""}
                    readOnly
                    style={{
                      border: "none",
                      borderBottom: "1px solid black certificate-input",
                      width: 300,
                      padding: 3,
                      textTransform: "capitalize",
                      textDecoration: "underline",
                    }}
                  />
                </div>
                <div style={{ marginBottom: 10 }}>
                  <label>Designation:</label>
                  <input
                    type="text"
                    // placeholder="Enter Designation"
                    defaultValue={"Proprietor"}
                    readOnly
                    style={{
                      border: "none",
                      borderBottom: "1px solid black certificate-input",
                      width: 200,
                      padding: 3,
                    }}
                  />
                </div>
                <div style={{ marginBottom: 10 }}>
                  <label>PAN of the Individual:</label>
                  <input
                    type="text"
                    // placeholder="Enter PAN"
                    defaultValue={

                      businessEnquiryData?.retailerData?.panNumber?businessEnquiryData?.retailerData?.panNumber: 
                      businessEnquiryData?.retailerPanNumber ? businessEnquiryData?.retailerPanNumber:''
                    
                    }
                    readOnly
                    style={{
                      border: "none",
                      borderBottom: "1px solid black certificate-input",
                      width: 300,
                      padding: 3,
                    }}
                  />
                </div>
                <div style={{ marginBottom: 10, display:'flex' }}>
                  <label style={{minWidth: '190px',lineHeight: 2}}>Office Address with Location: </label>
                  <input
                    type="text"
                    // placeholder="Enter Office Address"
                    defaultValue=  { businessEnquiryData?.retailerData?.district?businessEnquiryData?.retailerData?.district : "N/A"}
                    readOnly
                    style={{
                      border: "none",
                      borderBottom: "1px solid black certificate-input",
                      width: "80%",
                      padding: 3,
                      textTransform: "capitalize",
                      textDecoration: "underline",
                    }}
                  />
                </div>
                <div style={{ marginBottom: 10 }}>
                  <label>Telephone: </label>
                  <input
                    type="text"
                    // placeholder="Enter Telephone Number"
                    defaultValue={"N/A"}
                    readOnly
                    style={{
                      border: "none",
                      borderBottom: "1px solid black certificate-input",
                      width: 300,
                      padding: 3,
                    }}
                  />
                </div>
                <div style={{ marginBottom: 10 }}>
                  <label>Mobile: </label>
                  <input
                    type="text"
                    // placeholder="Enter Mobile Number"
                    defaultValue={
                      businessEnquiryData?.retailerData?.mobileNumber?businessEnquiryData?.retailerData?.mobileNumber: 

                      businessEnquiryData?.retailerMobileNumber
                        ? businessEnquiryData?.retailerMobileNumber
                        : "N/A"
                    }
                    readOnly
                    style={{
                      border: "none",
                      borderBottom: "1px solid black certificate-input",
                      width: 300,
                      padding: 3,
                      textDecoration: "underline",
                    }}
                  />
                </div>
                <div style={{ marginTop: 25 }}>
                  <div style={{ marginBottom: 10 }}>
                    <label>Date: </label>
                    <input
                      type="text"
                      // placeholder="Date"
                      defaultValue={todayDate}
                      readOnly
                      style={{
                        border: "none",
                        borderBottom: "1px solid black certificate-input",
                        width: 200,
                        padding: 3,
                      }}
                    />
                  </div>
                  <label>Place: </label>
                  <input
                    type="text"
                    placeholder="Place"
                    defaultValue={
                      businessEnquiryData?.retailerData?.district?businessEnquiryData?.retailerData?.district: 
                      businessEnquiryData?.retailerData?.district
                        ? businessEnquiryData?.retailerData?.district
                        : ""
                    }
                    readOnly
                    style={{
                      border: "none",
                      borderBottom: "1px solid black certificate-input",
                      width: 100,
                      padding: 3,
                      textTransform: "uppercase", // Ensures text is always uppercase
                    }}
                  />
                  <p />
                </div>
              </div>
              <div
                style={{
                  width: "40%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  <p>
                    (Signature) <br /> Office Seal
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div className="flex bottom-0 right-0 p-3  justify-end gap-3 absolute bg-white z-50 w-full">
        <ATMLoadingButton
          className="border-primary-main text-primary-main bg-white"
          onClick={onClose}
        >
          Close
        </ATMLoadingButton>

        <ATMLoadingButton
          type="submit"
          onClick={() => generatePDF()}
          className="bg-primary-main"
          loadingText="Sending..."
        >
          <div className=" font-medium  flex items-center">
            <span>Download</span>
          </div>
        </ATMLoadingButton>
      </div>
    </>
  );
};

export default DownloadDialog;
