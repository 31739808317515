export enum   AccessAction {
  LIST = "LIST",
  ADD = "ADD",
  EDIT = "EDIT",
  DELETE = "DELETE",
  CHANGE_APP_STATUS = "CHANGE_APPLICATION_STATUS",
  VIEW = "VIEW",
  APPLIACTION_VIEW_ON_CLICK = "VIEW_ON_CLICK",
  CHANGE_ASSIGNEE = "ASSIGN_TO_SJBT_USER",
  DASHBOARD_COUNT = "APPLICATION_COUNT_LIST",
  EDIT_CATEGORY_FOR_USER = "EDIT_CATEGORIES",
  HISTORY = "VIEW_HISTORY",
  EDIT_SERVICE_FOR_USER = "EDIT_SERVICES",
  EXPORT = "EXPORT",
  SHOW_ON_WEB ="SHOW_ON_WEB",
  SHOW_PENDNG_APP = "SHOW_PENDING_APPLICATION",
  SHOW_IN_PROGRESS_APP = "SHOW_IN_PROGRESS_APPLICATION",
  SHOW_VERIFIED_APP = "SHOW_VERIFIED_APPLICATION",
  SHOW_REJECTED_APP = "SHOW_REJECTED_APPLICATION",
  SHOW_GENERATED_APP = "SHOW_GENERATED_APPLICATION",
  SHOW_DONE_APP = "SHOW_DONE_APPLICATION",
  SHOW_CANCELLED_APP = "SHOW_CANCELLED_APPLICATION",
  CHECK_LOGS = "ACTIVITY_LOG_LIST",
  ADMIN_REPORTS = "ADMIN_REPORTS",
  DISTRIBUTOR_REPORTS = "DISTRIBUTOR_REPORTS",
  RETAILER_REPORTS = "RETAILER_REPORTS",
  BLOCK = "BLOCK_USER",
  ZIP = "DOWNLOAD_ZIP",
  CHECK_SUBSCRIPTION_HISTORY = "CHECK_SUBSCRIPTION_HISTORY",
  VIEW_WITH_CATEGORIES = "VIEW_WITH_CATEGORIES",
  SHOW_FOR_GUEST = "SHOW_FOR_GUEST",
  APPLICABLE_FOR_MINOR = "APPLICABLE_FOR_MINOR",
  ACTIVE_DEACTIVE = "ACTIVE/DEACTIVE",
  UPDATED_HISTORY ="UPDATED_HISTORY",
  UPDATE_USER_STATUS="UPDATE_USER_STATUS",
  ADMIN_CHANGE_PASSWORD ="ADMIN_CHANGE_PASSWORD",
  ADMIN_LOGO_UPLOAD ="ADMIN_LOGO_UPLOAD",
  EMPLOYER_CERTIFICATE ="EMPLOYER_CERTIFICATE",
  SHOW_PAYMENTS_DETAILS="SHOW_PAYMENTS_DETAILS",
  SHOW_FAILED_PAYMENTS = "FAILED_PAYMNENTS", 
  DIGITAL_PAN_APPLICATIONS='DIGITAL_PAN_APPLICATIONS',
  WORK_HISTORY ='WORK_HISTORY' ,
  SEARCH_HISTORY = 'SEARCH_HISTORY'
}
