import React from "react";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { Formik, Form, FormikHelpers } from "formik";
import { mixed, object } from "yup";
import Logo from "./AddLogo";
import { useAddLogoMutation } from "src/services/LogoServices";
import { useNavigate } from "react-router-dom";
import { showToast } from "src/utils/toaster/showToast";

export type LogoInitialValues = {
  image: any;
  fileType: string;
};

const AddLogoWrapper = () => {


  const navigate = useNavigate()
  const [addLogo] = useAddLogoMutation();

  // Form Initial Values
  const initialValues: LogoInitialValues = {
    image: "",
    fileType: "LOGO",
  };

  // Form Validation Schema
  const validationSchema = object({
    image: mixed().required("Image is required"),
  });

  // Form Submit Handler
  const handleSubmit = (
    values: LogoInitialValues,
    { setSubmitting }: FormikHelpers<LogoInitialValues>
  ) => {
    let formData = new FormData();
    formData.append("fileType", values.fileType);
    formData.append("image", values.image as File);
    addLogo(formData)
    .then((res: any) => {
      if (res.error) {
        showToast("error", res?.error?.data?.message);
        setSubmitting(false);
      } else {
        showToast("success", res?.data?.message);
        navigate("/logo-upload");
        setSubmitting(false);
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <SideNavLayout>
          <Form className="h-full">
            <Logo
              formikProps={formikProps}
            />
          </Form>
        </SideNavLayout>
      )}
    </Formik>
  );
};

export default AddLogoWrapper;
