import { PaginationType } from "src/models/common/PaginationType.model";
import { UpdateEmployerCertificate } from "src/models/EmployerCertificateUpdate.model";
import apiSlice from "./ApiSlice";

export const employerCertificateApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // POST REQUEST
    getemployerCertificate: builder.query({
      providesTags: ["employer-certificate"],
      query: (body: PaginationType) => ({
        url: "/employer-certificate/list/pagination",
        method: "POST",
        body,
      }),
    }),

    // GET REPORT
    getemployerCertificateZip: builder.mutation({
      query: (srn) => ({
        url: `/employer-certificate/zip`,
        params: { srn },
        method: "GET",
      }),
    }),

    // Export Data
    exportHistoryemployerCertificateData: builder.mutation({
      query: (body: PaginationType) => ({
        url: "/employer-certificate/list/pagination",
        method: "POST",
        body,
      }),
    }),

    // GET SINGLE
    getSingleemployerCertificate: builder.query({
      providesTags: ["employer-certificate"],
      query: (employerCertificateId) => ({
        url: `/employer-certificate/admin/view`,
        params: { id: employerCertificateId },
        method: "GET",
      }),
    }),

    // GET FLOW
    getemployerCertificateApplicationFlow: builder.mutation({
      query: (id) => ({
        url: `/employer-certificate/get-status-history`,
        params: { id: id },
        method: "PUT",
      }),
    }),

    // Change status
    changeemployerCertificateStatus: builder.mutation({
      invalidatesTags: ["employer-certificate", "pending-applications"],
      query: ({ body, id }: UpdateEmployerCertificate) => ({
        url: `/employer-certificate/update-status`,
        params: { id },
        method: "PUT",
        body,
      }),
    }),

    //assignee
    // changeemployerCertificateApplicationAssignee: builder.mutation({
    //   invalidatesTags: ["employer-certificate"],
    //   query: ({ body, id }: updateAssignee) => ({
    //     url: `/employer-certificate/assign-to`,
    //     params: { id },
    //     method: "POST",
    //     body,
    //   }),
    // }),

    // GET STATUS COUNT
    getemployerCertificateStatusWiseCount: builder.query({
      providesTags: ["employer-certificate"],
      query: () => ({
        url: "/employer-certificate/get-allstatuscounts",
        method: "GET",
      }),
    }),

    // GET STATUS WISE APPLICATIONS

    //PENDING  REQUEST
    getemployerCertificatePendingApplications: builder.query({
      providesTags: ["employer-certificate"],
      query: (body: PaginationType) => ({
        url: "/employer-certificate/get-pending-applications",
        method: "POST",
        body,
      }),
    }),

    //VERIFIED  REQUEST
    getemployerCertificateVerifiedApplications: builder.query({
      providesTags: ["employer-certificate"],
      query: (body: PaginationType) => ({
        url: "/employer-certificate/get-verified-applications",
        method: "POST",
        body,
      }),
    }),
    //REJECTED  REQUEST
    getemployerCertificateRejectedApplications: builder.query({
      providesTags: ["employer-certificate"],
      query: (body: PaginationType) => ({
        url: "/employer-certificate/get-rejected-applications",
        method: "POST",
        body,
      }),
    }),
    //IN RPOGRESS  REQUEST
    getemployerCertificateInProgressApplications: builder.query({
      providesTags: ["employer-certificate"],
      query: (body: PaginationType) => ({
        url: "/employer-certificate/get-inprogress-applications",
        method: "POST",
        body,
      }),
    }),
    //Done REQUEST
    getemployerCertificateDoneApplications: builder.query({
      providesTags: ["employer-certificate"],
      query: (body: PaginationType) => ({
        url: "/employer-certificate/get-done-applications",
        method: "POST",
        body,
      }),
    }),

    //CANCELLED REQUEST
    getemployerCertificateCancelledApplications: builder.query({
      providesTags: ["employer-certificate"],
      query: (body: PaginationType) => ({
        url: "/employer-certificate/get-cancelled-applications",
        method: "POST",
        body,
      }),
    }),

    //Generate REQUEST
    getemployerCertificateGenerateApplications: builder.query({
      providesTags: ["employer-certificate"],
      query: (body: PaginationType) => ({
        url: "/employer-certificate/get-generate-applications",
        method: "POST",
        body,
      }),
    }),

    // work history employerCertificate Application
    getemployerCertificateApplicationWorkHistory: builder.query({
      providesTags: ["employer-certificate"],
      query: (body: any) => ({
        url: "/employer-certificate/admin-history",
        method: "POST",
        body,
      }),
    }),

    //To Get Payemnt summarry 
     
     getemployerCertificateApplicationPaymentSummary: builder.query({
      providesTags: ["employer-certificate"],
      query: (body: any) => ({
        url: "/employer-certificate/payment-detail",
        method: "POST",
        body,
      }),
    }),


       // ADD
    addEmployerCertificate: builder.mutation({
      invalidatesTags: ["employer-certificate"],
      query: (body) => ({
        url: "/employer-certificate/add",
        method: "POST",
        body,
      }),
    }),
     

  }),
});

export const {
  useGetemployerCertificateQuery,
  useGetSingleemployerCertificateQuery,
  useGetemployerCertificateApplicationPaymentSummaryQuery,
  // useChangeemployerCertificateApplicationAssigneeMutation,
  useGetemployerCertificatePendingApplicationsQuery,
  useChangeemployerCertificateStatusMutation,
  useExportHistoryemployerCertificateDataMutation,
  useGetemployerCertificateZipMutation,
  useGetemployerCertificateStatusWiseCountQuery,
  useGetemployerCertificateCancelledApplicationsQuery,
  useGetemployerCertificateDoneApplicationsQuery,
  useGetemployerCertificateGenerateApplicationsQuery,
  useGetemployerCertificateInProgressApplicationsQuery,
  useGetemployerCertificateRejectedApplicationsQuery,
  useGetemployerCertificateVerifiedApplicationsQuery,
  useGetemployerCertificateApplicationFlowMutation,
  useGetemployerCertificateApplicationWorkHistoryQuery,
  useAddEmployerCertificateMutation
} = employerCertificateApi;
