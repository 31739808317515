import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
import { date, object } from "yup";
import FilterEmployerCertificateCard from "./FilterEmployerCertificateCard";
import { useLocation } from "react-router-dom";
import {
  setDateFilter,
  setFilterBy
} from "src/redux/slices/EmployerCertificateUpdateSlice";
type Props = {
  close: () => void;
};

export type FormInitialValues = {
  // status: string;
  startDate: string | null;
  endDate: string | null;

};

const FilterEmployerCertificateCardWrapper = ({ close }: Props) => {


  const dispatch = useDispatch<AppDispatch>();
  const { search  } = useLocation()
  const queryParams = new URLSearchParams(search)

  // const applicationStatus: any | string | null = queryParams.get('status')

  const EmployerCertificateDoneApplicationState: any = useSelector(
    (state: RootState) => state.EmployerCertificateDoneApplication
  );
  const { filterBy, dateFilter } = EmployerCertificateDoneApplicationState;

  // Initial Values
  const initialValues: FormInitialValues = {
    // status: filterBy?.[0]?.value?.length ? '' : currentPath || "",
    // status: filterBy?.[0]?.value?.length ? filterBy?.[0]?.value?.[0] : applicationStatus || "",
    startDate: dateFilter.start_date || null,
    endDate: dateFilter.end_date || null,

  };

  // Validation Schema
  const validationSchema = object({
    startDate: date()
      .nullable()
      .max(moment(), "Date should not be after current date"),
    endDate: date()
      .nullable()
      .when("startDate", (startDate, schema) => {
        if (startDate) {
          return schema.min(startDate, "Must be after start date");
        }
      }),
    doneDateFrom: date()
      .nullable()
      .max(moment(), "Date should not be after current date"),
    doneDateTo: date()
      .nullable()
      .when("doneDateFrom", (doneDateFrom, schema) => {
        if (doneDateFrom) {
          return schema.min(doneDateFrom, "Must be after start date");
        }
      }),
  });

  // Submit Handler
  const handleSubmit = async (
    values: FormInitialValues,
    { setSubmitting }: FormikHelpers<FormInitialValues>
  ) => {
    setSubmitting(false);
    dispatch(
      setDateFilter({
        start_date: values.startDate
          ? moment(values.startDate)?.format("yyyy-MM-DD")
          : "",
        end_date: values.endDate
          ? moment(values.endDate)?.format("yyyy-MM-DD")
          : "",

      })
    );
    await dispatch(
      setFilterBy(
        filterBy?.map((filter: any) => {
          switch (filter.fieldName) {
            // case "status":
            //   return {
            //     ...filter,
            //     value: values.status ? [values.status] : [],
            //   };


            default:
              return filter;
          }
        })
      )
    );

    close();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<FormInitialValues>) => {
    await dispatch(
      setDateFilter({
        start_date: "",
        end_date: "",
      })
    );
    await dispatch(
      setFilterBy(
        filterBy?.map((filter: any) => {
          switch (filter.fieldName) {
            // case "status":
            //   return {
            //     ...filter,
            //     value: [],
            //   };
            default:
              return filter;
          }
        })
      )
    );
    formikProps.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <FilterEmployerCertificateCard
            formikProps={formikProps}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default FilterEmployerCertificateCardWrapper;
