import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  resetState,
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/EmployerCertificateUpdateSlice";
import { AppDispatch, RootState } from "src/redux/store";
import { formatDateAndTime } from "src/utils/dateAndTime";
import  EmployerCertificate from "./EmployerCertificate";
import { getColumns } from "src/utils/auth/getColumns";
import { useGetemployerCertificateDoneApplicationsQuery } from "src/services/EmployerCertificateService";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import DownloadDialogWrapper from "../list/Dialog/DownloadDialogBox/DownloadDialogWrapper";
import { EmployerCertificateUpdateListResponse } from "src/models/EmployerCertificateUpdate.model";
import { Tooltip } from "@mui/material";

const paramList = [
  "category",
  "name",
  "email",
  "adhaarNumber",
  "mobileNumber",
  "distributorCode",
  "appliedBy",
  "appliedAs",
  "txnId",
  "appliedFrom",
  "paymentCategory",
  "version",
  "panNumber",
  "acknowledgementNumber",
  "status",
  "assignedTo",
  "assignedBy",
];

const  EmployerCertificateWrapper = () => {
  // Dispatch Hook
  const dispatch = useDispatch<AppDispatch>();
  // Slice
  const EmployerCertificateDoneApplicationState: any = useSelector(
    (state: RootState) => state.EmployerCertificateDoneApplication
  );
  const { items, searchValue, rowsPerPage, page, filterBy, dateFilter , orderByValue } =
  EmployerCertificateDoneApplicationState;

  useEffect(() => {
    dispatch(resetState());
  }, []);


  const [isOpenDonwloadDialog, setIsOpenDownloadDialog] = useState(false);
  const [rowData, setRowData] = useState<EmployerCertificateUpdateListResponse | null>(null);

  // Get Data Query
  const { data, isFetching, isLoading } = useGetemployerCertificateDoneApplicationsQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: paramList,
    page: page,
    filterBy: filterBy,
    dateFilter: dateFilter,
    orderBy: "createdDateToShow",
    orderByValue: orderByValue,
    isPaginationRequired: true,
  });

  // Setting data
  React.useEffect(() => {

    console.log("filterBy", filterBy);
    if (!isFetching && !isLoading) {
      console.log("calling");
      dispatch(setIsTableLoading(false));
      dispatch(setItems(data?.data || []));
      dispatch(setTotalItems(data?.totalItem || 0));
    } else {
      dispatch(setIsTableLoading(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFetching, data]);



  const columns: columnTypes[] = [
    {
      field: "createdDateToShow",
      headerName: "Date - Time",
      flex: "flex-[1_1_0%]",
      renderCell: (row: EmployerCertificateUpdateListResponse) => (
        <div>
          <div className="text-slate-700 font-medium">
            {formatDateAndTime(row?.createdDateToShow, "DD MMM yyyy")}
          </div>
          <div className="text-[13px] font-medium text-slate-400">
            {formatDateAndTime(row?.createdDateToShow, "hh:mm A")}
          </div>
        </div>
      ),
    },
    {
      field: "srn",
      headerName: "SRN",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row: EmployerCertificateUpdateListResponse) => {
        return (
          <span className="text-primary-main text-[13px] text-ellipsis overflow-hidden">
            {" "}
            {row.srn}{" "}
          </span>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: "flex-[1_1_0%]",
      renderCell: (row: EmployerCertificateUpdateListResponse) => (
        <span className="text-ellipsis overflow-hidden"> {row.name} </span>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row: EmployerCertificateUpdateListResponse) => {
        return (
          <span className="text-ellipsis overflow-hidden">
            {" "}
            {row.email || "N/A"}{" "}
          </span>
        );
      },
    },
    {
      field: "mobileNumber",
      headerName: "Mobile",
      flex: "flex-[1_1_0%]",
    },
    {
      noAuthRequired: true,
      field: "distributorCode",
      headerName: "SJBT  Code",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row: EmployerCertificateUpdateListResponse) => {
        return <span> {row.distributorCode || "N/A"} </span>;
      },
    },
    {
      noAuthRequired: true,
      field: "assignedTo",
      headerName: "Assignee",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row: EmployerCertificateUpdateListResponse) => {
        return (
          <div className="text-primary-main py-[10px] ">
            {row.assignedToName || "Not Assigned"}
          </div>
        );
      },
    },
    {
      field: "appliedByNumber",
      headerName: "Applied By (Mob. No.)",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row: EmployerCertificateUpdateListResponse) => {
        return <span> {row.appliedByNumber || "N/A"} </span>;
      },
    },
    {
      field: "acknowledgementNumber",
      headerName: "Acknowledgement Number",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row: EmployerCertificateUpdateListResponse) => {
        return (
          <Tooltip title={row.acknowledgementNumber}>
            <span className="text-primary-main text-[13px] text-ellipsis overflow-hidden">
               {row.acknowledgementNumber || "-"}
            </span>
          </Tooltip>
        );
      },
    },
      {
          noAuthRequired: true,
          field: "action",
          headerName: "Action",
          flex: "flex-[0_0_100px]",
          renderCell: (row: EmployerCertificateUpdateListResponse) => {
            // Handle click action to open the dialog with row data
            const handleClick = () => {
              setRowData(row);
              setIsOpenDownloadDialog(true);
            };
        
            // Ensure file URL exists before allowing download
            const handleDownload = () => {
              if (row?.employerertificateFile) {
                downloadPdfFile(row.employerertificateFile);
              } else {
                console.error("File URL is missing.", row);
              }
            };

            // let isGeneratable = true;
            // return (
            //   <button
            //     className={`py-1 px-3 rounded flex gap-2 items-center ${
            //       isGeneratable
            //         ? "bg-green-500 text-white hover:bg-green-600"
            //         : "bg-gray-500 text-white cursor-not-allowed"
            //     }`}
            //     onClick={isGeneratable ? handleClick : undefined}
            //     disabled={!isGeneratable}
            //     title={!isGeneratable ? "Status is not VERIFY" : ""}
            //   >
            //     Generate
            //   </button>
            // );


        
            // If the certificate status exists, render the "Download" button
            if (row?.employercertificatesData?.status) {
              return (
                <span
                  className="text-green-500 font-medium flex gap-1 items-center cursor-pointer"
                  onClick={handleDownload}
                >
                  Download
                </span>
              );
            }
        
            // If status is "VERIFY", "GENERATE", or "DONE", render "Generate" button; otherwise, disable it
    
            console.log("row?.status", row?.status);
            const isGeneratable = ["VERIFY", "GENERATE", "DONE"].includes(row?.status);
            console.log("isGeneratable", isGeneratable);
            return (
              <button
                className={`py-1 px-3 rounded flex gap-2 items-center ${
                  isGeneratable
                    ? "bg-green-500 text-white hover:bg-green-600"
                    : "bg-gray-500 text-white cursor-not-allowed"
                }`}
                onClick={isGeneratable ? handleClick : undefined}
                disabled={!isGeneratable}
                title={!isGeneratable ? "Status is not VERIFY" : ""}
              >
                Generate
              </button>
            );
          },
    // {
    //   noAuthRequired: true,
    //   field: "ACTION",
    //   headerName: "Action",
    //   flex: "flex-[0_0_100px]",
    //   renderCell: (row: EmployerCertificateUpdateListResponse) => {
    //     // Handle the click action to open the dialog with the row data
    //     const handleClick = () => {
    //       console.log(row, "rowrowrowrow");
    //       setRowData(row);
    //       setIsOpenDownloadDialog(true);
    //     };
      
    //     // If the certificate status exists, render the "Download" option
    //     if (row?.employercertificatesData?.status) {
    //       return (
    //         <span
    //           className="text-green-500 font-medium flex gap-1 items-center"
    //           onClick={() => downloadPdfFile(row?.employerertificateFile)} // Ensure downloadPdfFile is defined
    //         >
    //           Download
    //         </span>
    //       );
    //     }
      
    //     // If status is "VERIFY", render the "Generate" button, otherwise render a disabled button with a tooltip
    //     return row?.status === "VERIFY" ? (
    //       <button
    //         className="bg-green-500 text-white hover:bg-green-600 py-1 px-3 rounded flex gap-2 items-center"
    //         onClick={handleClick}
    //       >
    //         Generate
    //       </button>
    //     ) : (
    //       <button
    //         className="bg-gray-500 text-white py-1 px-3 rounded flex gap-2 items-center cursor-not-allowed"
    //         title="Status is not VERIFY"
    //         disabled
    //       >
    //         Generate
    //       </button>
    //     );
    //   },
      
    },
  ];


  
  const download = (url: string) => {
    window.open(url);
  }


const downloadPdfFile = async (pdfUrl: string) => {
    try {
      const response = await fetch(pdfUrl, {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch the PDF file.');
      }
  
      const arrayBuffer = await response.arrayBuffer();
      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
  
      const segments = pdfUrl.split(/[\/?#]/);
      const fileName = segments[segments.length - 1] || 'downloaded-file.pdf';
  
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };


  return (

    <>
      <SideNavLayout>

        {/* <>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Retailer</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={agent}
            label="Age"
            onChange={handleChange}
          >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
        </> */}
        <EmployerCertificate
          columns={getColumns(columns, "EMPLOYER_CERTIFICATE")}
          rows={items.map((item:any) => ({ ...item, key: item._id || item.srn }))} 
        />
      </SideNavLayout>
      {isOpenDonwloadDialog && (
        <DownloadDialogWrapper
          onClose={() => setIsOpenDownloadDialog(false)}
          businessEnquiryData={rowData}
        />
      )}
    </>
  );

};

export default  EmployerCertificateWrapper;
