import { FormikProps } from "formik";
import React from "react";
import ATMTextField from "../UI/atoms/formFields/ATMTextField/ATMTextField";
import ATMLoadingButton from "../UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMFilePickerWrapper from "../UI/atoms/formFields/ATMFileUploader/ATMFileUploaderWrapper";
import { VerificationInitialValues } from "./ReuploadAcknowledgementDialogWrapper";
import { getAcknowledgementNumber , isValidFileName  } from "src/utils/acknowledgementNumber/acknowledgementNumber";
import { showToast } from "src/utils/toaster/showToast";

type Props = {
  formikProps: FormikProps<VerificationInitialValues>;
  onClose: () => void;
  type:string
  
};

const ReuploadAcknowledgementDialog = ({ formikProps, onClose, type }: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

  return (
    <div className=" flex flex-col overflow-auto   rounded  bg-white relative ">
      <div className="grow overflow-auto">


      {
          type == 'PAN'?

          <>
           {/* Acknowledgement Name */}
           <div className="py-3 w-[90%]">
          <ATMTextField
            className=" py-5"
            name="acknowledgement_name"
            value={values.acknowledgement_name}
            onChange={(e) => {
              setFieldValue("acknowledgement_name", e.target.value);
            }}
            label="Acknowledgement Name"
            placeholder="Acknowledgement Name"
          />
        </div>

         {/* Father Name */}
         <div className="py-3 w-[90%]">
          <ATMTextField
            className=" py-5"
            name="acknowledgement_father_name"
            value={values.acknowledgement_father_name}
            onChange={(e) => {
              setFieldValue("acknowledgement_father_name", e.target.value);
            }}
            label="Father Name"
            placeholder="Father Name"
          />
        </div>


         {/* Residential address */}
         <div className="py-3 w-[90%]">
          <ATMTextField
            className=" py-5"
            name="acknowledgement_address"
            value={values.acknowledgement_address}
            onChange={(e) => {
              setFieldValue("acknowledgement_address", e.target.value);
            }}
            label="Residential address"
            placeholder="Residential address"
          />
        </div>
        </>:null
         }
        
        {/* Acknowledgement */}
        <div className="py-2 w-[90%]">
        <div className="py-2 w-[90%]">
          <ATMFilePickerWrapper
            name="acknowledgement"
            selectedFile={values.acknowledgement}
            accept="application/pdf"
            onSelect={(newValue: any) => {
             if(type === 'PAN'){
              if (isValidFileName(newValue.name)) {
                setFieldValue("acknowledgement", newValue);
                setFieldValue(
                  "acknowledgementNumber",
                  getAcknowledgementNumber(newValue.name)
                );
              } else {
                showToast("error", "Invalid File name");
                setFieldValue("acknowledgement", "");
              }
             }else{
              setFieldValue("acknowledgement", newValue);
              setFieldValue(
                "acknowledgementNumber",
                ''
              );
             }
            }}
            label="Acknowledgement"
            hideCloseButton
          />
        </div>
        </div>
        {/* Remark */}
        <div className="py-3 w-[90%]">
          <ATMTextField
            className=" py-5"
            name="remark"
            value={values.remark}
            onChange={(e) => {
              setFieldValue("remark", e.target.value);
            }}
            label="Remark"
            placeholder="Remark"
          />
        </div>
      </div>

      <div className="flex justify-end pt-3">
        <div className="flex justify-end gap-3">
          <ATMLoadingButton
            className="border-primary-main text-primary-main bg-white"
            onClick={onClose}
          >
            Cancel
          </ATMLoadingButton>

          <ATMLoadingButton
            type="submit"
            isLoading={isSubmitting}
            className="bg-primary-main"
          >
            Upload
          </ATMLoadingButton>
        </div>
      </div>
    </div>
  );
};

export default ReuploadAcknowledgementDialog;
