import { UpdateLogo } from "src/models/LogoUpdate.model";
import { PaginationType } from "src/models/common/PaginationType.model";
import apiSlice from "./ApiSlice";

export const logoApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET REQUEST
    getLogoList: builder.query({
      providesTags: ["logo"],
      query: (body: PaginationType) => ({
        url: "/logo/list/pagination",
        method: "POST",
        body,
      }),
    }),

    // GET REQUEST
    getLogoById: builder.query({
      providesTags: ["logo"],
      query: (id: string) => ({
        url: `/logo/admin/view`,
        params : {id},
        method: "GET",
      }),
    }),

    // ADD
    addLogo: builder.mutation({
      invalidatesTags: ["logo"],
      query: (body) => ({
        url: "/logo/add",
        method: "POST",
        body,
      }),
    }),

    // **** DELETE BY ID
    DeleteLogoById: builder.mutation({
      invalidatesTags: ["logo"],
      query: (id: string) => ({
        url: `/logo/delete`,
        params : {id},
        method: "DELETE",
      }),
    }),

    // UPDATE
    updateLogo: builder.mutation({
      invalidatesTags: ["logo"],
      query: ({ body, id }: UpdateLogo) => ({
        url: `/logo/update`,
        params : {id},
        method: "PUT",
        body,
      }),
    }),

    // Change show On web Status
    changeLogoShowOnWebStatus: builder.mutation({
      invalidatesTags: ["logo"],
      query: (logoId) => ({
        url: `/logo/change-showonweb`,
        params : {
          id : logoId
        },
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useGetLogoListQuery,
  useDeleteLogoByIdMutation,
  useAddLogoMutation,
  useGetLogoByIdQuery,
  useUpdateLogoMutation,
  useChangeLogoShowOnWebStatusMutation,
} = logoApi;
